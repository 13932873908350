import researchImage from '../assets/images/work-process/research.svg';
import planningImage from '../assets/images/work-process/planning.svg';
import developmentImage from '../assets/images/work-process/development.svg';
import feedbackImage from '../assets/images/work-process/feedback.svg';
import finalizationImage from '../assets/images/work-process/finalization.svg';
// import nextImage from '../assets/images/work-process/next.svg';
import nextImage from '../assets/images/work-process/next-arrow.gif';

export const WorkProcessComponent = (props) => {
    return (
        <div ref={props.workProcessRef} data-section id="workProcess" className="w-full pt-20 md:pt-10">
            <div className="w-full text-center text-3xl">
                <div className="text-xl text-contrast font-medium">
                    <div className='px-8 md:px-96 pb-5'>
                        <hr className='border border-accent' />
                    </div>
                    How we
                </div>
                <div className="text-5xl text-accent font-semibold">
                    Approach
                    <span className="text-2xl text-contrast font-medium">&nbsp;&nbsp;Problem ?</span>
                </div>
            </div>
            <div className='flex flex-wrap w-full justify-center items-center gap-10 md:gap-0 px-20 py-10'>
                <div className='flex flex-wrap justify-center rounded-lg border border-[#06b6d1] px-0 w-full md:w-52 py-4 h-64'>
                    <img src={researchImage} alt="Research" className='w-full h-12 mb-6' />
                    <div className='w-full text-center mb-4 text-xl pt-4 font-medium text-[#06b6d1] h-16'>
                        Problem Analysis
                    </div>
                    <div className='w-full pt-2 text-justify font-normal h-2/5 text-[#06b6d1] px-3'>
                        Deep understanding of your needs and market trends.
                    </div>
                </div>
                    <img src={nextImage} alt="Next" className='w-15 h-20 hidden md:block' />
                <div className='flex flex-wrap justify-center rounded-lg border border-[#ff4aed] px-0 w-full md:w-52 py-4 h-64'>
                    <img src={planningImage} alt="Research" className='w-full h-12 mb-6' />
                    <div className='w-full text-center mb-4 text-xl pt-4 font-medium text-[#ff4aed] h-16'>
                        Planning
                    </div>
                    <div className='w-full pt-2 text-justify font-normal h-2/5 text-[#ff4aed] px-3'>
                        Strategic project scope, timeline, and resource allocation.
                    </div>
                </div>
                    <img src={nextImage} alt="Next" className='w-15 h-20 hidden md:block' />
                <div className='flex flex-wrap justify-center rounded-lg border border-[#FF9800] px-0 w-full md:w-52 py-4 h-64'>
                    <img src={developmentImage} alt="Research" className='w-full h-12 mb-6' />
                    <div className='w-full text-center mb-4 text-xl pt-1 font-medium text-[#FF9800] h-16'>
                        Design & Development
                    </div>
                    <div className='w-full pt-2 text-justify font-normal h-2/5 text-[#FF9800] px-3'>
                        Innovation-driven solutions with user focus.
                    </div>
                </div>
                    <img src={nextImage} alt="Next" className='w-15 h-20 hidden md:block' />
                <div className='flex flex-wrap justify-center rounded-lg border border-[#9C27B0] px-0 w-full md:w-52 py-4 h-64'>
                    <img src={feedbackImage} alt="Research" className='w-full h-12 mb-6' />
                    <div className='w-full text-center mb-4 text-xl pt-4 font-medium text-[#9C27B0] h-16'>
                        Feedback & Iteration
                    </div>
                    <div className='w-full pt-2 text-justify font-normal h-2/5 text-[#9C27B0] px-3'>
                        Continuous improvement based on your input.
                    </div>
                </div>
                    <img src={nextImage} alt="Next" className='w-15 h-20 hidden md:block' />
                <div className='flex flex-wrap justify-center rounded-lg border border-[#4CAF50] px-0 w-full md:w-52 py-4 h-64'>
                    <img src={finalizationImage} alt="Research" className='w-full h-12 mb-6' />
                    <div className='w-full text-center mb-4 text-xl pt-4 font-medium text-[#4CAF50] h-16'>
                        Final Delivery
                    </div>
                    <div className='w-full pt-2 text-justify font-normal h-2/5 text-[#4CAF50] px-3'>
                        Seamless integration into your operations.
                    </div>
                </div>
            </div>
        </div>
    )
}