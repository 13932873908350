import webDevelopmentImage from '../assets/images/services/web_img.svg';
import mobileAppDevelopmentImage from '../assets/images/services/mobile_img.svg';
import desktopAppDevelopmentImage from '../assets/images/services/desktop_img.svg';
import frameworkDevelopmentImage from '../assets/images/services/framework_img.svg';
import uiuxDesigningImage from '../assets/images/services/uiux_img.svg';
import logoDesigningImage from '../assets/images/services/logo_designing_img.svg';
import businessImage from '../assets/images/services/business_img.svg';
import seoImage from '../assets/images/services/seo_image.svg';
import contentWritingImage from '../assets/images/services/content_writing_img.svg';

export const ServicesComponent = (props) => {
    return (
        <div ref={props.servicesRef} data-section id="services" className="w-full pt-20 md:pt-0">
            <div className="w-full text-center text-3xl">
                <div className="text-xl text-contrast font-medium">
                    <div className='px-8 md:px-96 pb-5'>
                        <hr className='border border-accent' />
                    </div>
                    What
                </div>
                <div className="text-5xl text-accent font-semibold">
                    Services
                    <span className="text-2xl text-contrast font-medium">&nbsp;&nbsp;We Provide?</span>
                </div>
            </div>
            <div className='flex flex-wrap items-center justify-center gap-10 w-full py-10 md:px-0 px-8'>
                <div className="flex flex-wrap items-center justify-center w-full h-70 gap-2 md:w-2/12 border border-[#21a0cf] p-5 border-groove rounded-xl hover:border-[2.5px] cursor-default">
                    <img src={webDevelopmentImage} alt="web development" className='w-12 h-12' />
                    <div className='flex items-center justify-center pt-4 w-full text-center text-xl font-medium text-[#21a0cf] h-12'>
                        Web Development
                    </div>
                    <div className='w-full pt-6 text-justify font-normal text-[#21a0cf] h-32'>
                        From dynamic websites to complex web applications, our web development expertise brings your ideas to life.
                    </div>

                </div>
                <div className="flex flex-wrap items-center justify-center w-full h-70 gap-2 md:w-2/12 border border-[#eb5267] p-5 border-groove rounded-xl hover:border-[2.5px] cursor-default">
                    <img src={mobileAppDevelopmentImage} alt="web development" className='w-12 h-12' />
                    <div className='flex items-center justify-center pt-4 w-full text-center text-xl font-medium text-[#eb5267]  h-12'>
                        Mobile Application Development
                    </div>
                    <div className='w-full pt-6 text-justify font-normal h-32 text-[#eb5267]'>
                        Crafting innovative and user-friendly mobile apps across platforms to ensure a seamless digital experience.
                    </div>
                </div>
                <div className="flex flex-wrap items-center justify-center w-full h-70 gap-2 md:w-2/12 border border-[#2f4a96] p-5 border-groove rounded-xl hover:border-[2.5px] cursor-default">
                    <img src={desktopAppDevelopmentImage} alt="web development" className='w-12 h-12' />
                    <div className='flex items-center justify-center pt-4 w-full text-center text-xl font-medium text-[#2f4a96]  h-12'>
                        Desktop Application Development
                    </div>
                    <div className='w-full pt-6 text-justify font-normal h-32 text-[#2f4a96]'>
                        Developing robust desktop applications that cater to your specific needs and enhance your operations.
                    </div>
                </div>
                <div className="flex flex-wrap items-center justify-center w-full h-70 gap-2 md:w-2/12 border border-[#6cb019] p-5 border-groove rounded-xl hover:border-[2.5px] cursor-default">
                    <img src={frameworkDevelopmentImage} alt="web development" className='w-12 h-12' />
                    <div className='flex items-center justify-center pt-4 w-full text-center text-xl font-medium text-[#6cb019]  h-12'>
                        Framework Development
                    </div>
                    <div className='w-full pt-6 text-justify font-normal h-32 text-[#6cb019]'>
                        Building custom frameworks that provide a strong foundation for your projects and accelerate development.
                    </div>
                </div>
                <div className="flex flex-wrap items-center justify-center w-full h-70 gap-2 md:w-2/12 border border-[#fc873a] p-5 border-groove rounded-xl hover:border-[2.5px] cursor-default">
                    <img src={uiuxDesigningImage} alt="web development" className='w-12 h-12' />
                    <div className='flex items-center justify-center pt-4 w-full text-center text-xl font-medium text-[#fc873a]  h-12'>
                        UI/UX Designing
                    </div>
                    <div className='w-full pt-6 text-justify font-normal h-32 text-[#fc873a]'>
                        Creating intuitive and visually appealing user interfaces that prioritize user experience.
                    </div>
                </div>
                <div className="flex flex-wrap items-center justify-center w-full h-70 gap-2 md:w-2/12 border border-[#75348a] p-5 border-groove rounded-xl hover:border-[2.5px] cursor-default">
                    <img src={logoDesigningImage} alt="web development" className='w-12 h-12' />
                    <div className='flex items-center justify-center pt-4 w-full text-center text-xl font-medium text-[#75348a]  h-12'>
                        Logo Designing
                    </div>
                    <div className='w-full pt-6 text-justify font-normal h-32 text-[#75348a]'>
                        Crafting unique and impactful logos that represent your brand's identity and values.
                    </div>
                </div>
                <div className="flex flex-wrap items-center justify-center w-full h-70 gap-2 md:w-2/12 border border-[#de3c3c] p-5 border-groove rounded-xl hover:border-[2.5px] cursor-default">
                    <img src={businessImage} alt="web development" className='w-12 h-12' />
                    <div className='flex items-center justify-center pt-4 w-full text-center text-xl font-medium text-[#de3c3c]  h-12'>
                        Business Solutions
                    </div>
                    <div className='w-full pt-6 text-justify font-normal h-32 text-[#de3c3c]'>
                        Tailored solutions to address your business challenges, enhance efficiency, and drive growth.
                    </div>
                </div>
                <div className="flex flex-wrap items-center justify-center w-full h-70 gap-2 md:w-2/12 border border-[#d68722] p-5 border-groove rounded-xl hover:border-[2.5px] cursor-default">
                    <img src={seoImage} alt="web development" className='w-12 h-12' />
                    <div className='flex items-center justify-center pt-4 w-full text-center text-xl font-medium text-[#d68722] h-12'>
                        SEO Development
                    </div>
                    <div className='w-full pt-6 text-justify font-normal h-32 text-[#d68722]'>
                        Boosting your online visibility and reaching your target audience through effective SEO strategies.
                    </div>
                </div>
                <div className="flex flex-wrap items-center justify-center w-full h-70 gap-2 md:w-2/12 border border-[#02ad92] p-5 border-groove rounded-xl hover:border-[2.5px] cursor-default">
                    <img src={contentWritingImage} alt="web development" className='w-12 h-12' />
                    <div className='flex items-center justify-center pt-4 w-full text-center text-xl font-medium text-[#02ad92] h-12'>
                        Content Writing
                    </div>
                    <div className='w-full pt-6 text-justify font-normal h-32 text-[#02ad92]'>
                        Delivering compelling and relevant content that engages, informs, and converts.
                    </div>
                </div>

            </div>
        </div>
    )
}
