import { useEffect, useState } from "react";
import logo from '../assets/images/logo.svg';


export const NavbarComponent = (props) => {

    const [navbar, setNavbar] = useState(false);
    const [activeSection, setActiveSection] = useState('home');

    const handleScroll = () => {

        const pageYOffset = window.scrollY;
        let newActiveSection = null;
        const sections = document.querySelectorAll('[data-section]');
        sections.forEach((section) => {
            const sectionOffsetTop = section.offsetTop;
            const sectionHeight = section.offsetHeight;

            if (pageYOffset >= sectionOffsetTop - 150 && pageYOffset
                < sectionOffsetTop + sectionHeight) {
                newActiveSection = section.id;
            }

            if (window.scrollY + window.innerHeight >= document.documentElement.scrollHeight - 2) {
                newActiveSection = "contactUs";
            }
        });
        setActiveSection(newActiveSection);
    };

    useEffect(() => {
        const sections = document.querySelectorAll('[data-section]');
        sections.current = document.querySelectorAll('[data-section]');
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const handelHomeScroll = (section) => {
        if (section) {
            const rect = section.getBoundingClientRect();
            const scrollTop = document.documentElement.scrollTop;
            const targetPosition = rect.top + scrollTop - 80;

            window.scrollTo({
                top: targetPosition,
                // behavior: 'smooth'
            });
            if (navbar) setNavbar(false);
        }
    }

    const handelServicesScroll = (section) => {
        if (section) {
            const rect = section.getBoundingClientRect();
            const scrollTop = document.documentElement.scrollTop;
            const targetPosition = rect.top + scrollTop - 130;

            window.scrollTo({
                top: targetPosition,
                // behavior: 'smooth'
            });
            if (navbar) setNavbar(false);
        }
    }

    const handelShowcaseScroll = (section) => {
        if (section) {
            const rect = section.getBoundingClientRect();
            const scrollTop = document.documentElement.scrollTop;
            const targetPosition = rect.top + scrollTop - 80;

            window.scrollTo({
                top: targetPosition,
                // behavior: 'smooth'
            });
            if (navbar) setNavbar(false);
        }
    }

    const handelWorkProcessScroll = (section) => {
        if (section) {
            const rect = section.getBoundingClientRect();
            const scrollTop = document.documentElement.scrollTop;
            const targetPosition = rect.top + scrollTop - 80;

            window.scrollTo({
                top: targetPosition,
                // behavior: 'smooth'
            });
            if (navbar) setNavbar(false);
        }
    }

    const handelAboutUsScroll = (section) => {
        if (section) {
            const rect = section.getBoundingClientRect();
            const scrollTop = document.documentElement.scrollTop;
            const targetPosition = rect.top + scrollTop - 80;

            window.scrollTo({
                top: targetPosition,
                // behavior: 'smooth'
            });
            if (navbar) setNavbar(false);
        }
    }

    const handelContactUsScroll = (section) => {
        if (section) {
            const rect = section.getBoundingClientRect();
            const scrollTop = document.documentElement.scrollTop;
            const targetPosition = rect.top + scrollTop - 80;

            window.scrollTo({
                top: targetPosition,
                // behavior: 'smooth'
            });
            if (navbar) setNavbar(false);
        }
    }

    return (

        <nav className="bg-contrast fixed w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600">
            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                <a href="https://flowbite.com/" className="flex items-center">
                    <img src={logo} className="h-14 w-14 mr-1 bg-red-500" alt="Infix Logo"  />
                    {/* <span className="pb-2 text-xl font-semibold whitespace-nowrap text-text-primary">INFIX</span> */}
                </a>
                <div className="flex md:order-2">
                    <button type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:none font-medium rounded-lg text-sm px-4 py-2 invisible text-center mr-3 md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"></button>
                    <button
                        data-collapse-toggle="navbar-sticky"
                        type="button"
                        onClick={() => setNavbar(!navbar)}
                        className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 ring-1 ring-text-primary dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-sticky" aria-expanded="false">
                        {navbar ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-8 h-8 text-white"
                                viewBox="0 0 20 20"
                                fill="#fff"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>

                        ) : (
                            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                                <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
                            </svg>
                        )}
                    </button>
                </div>
                <div className={`items-center justify-between ${navbar ? "block h-[100vh]" : "hidden"} w-full md:flex md:w-auto md:order-1`} id="navbar-sticky">
                    <ul className="flex flex-col gap-8 md:gap-0 p-2 md:p-0 text-text-secondary mt-4 font-medium rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                        <li className={`border border-x-0 border-t-0 pb-1 border-transparent border-4 ${activeSection === "home" ? 'text-accent border-accent' : 'border-contrast'} hover:text-accent hover:border-accent cursor-pointer`}>
                            <div onClick={() => {
                                const section = props.refMap.homeRef.current;
                                handelHomeScroll(section);
                            }}>Home</div>
                        </li>
                        <li className={`border border-x-0 border-t-0 pb-1 border-transparent border-4 ${activeSection === "services" ? 'text-accent border-accent' : 'border-contrast'} hover:text-accent hover:border-accent cursor-pointer`}>
                            <div onClick={() => {
                                const section = props.refMap.servicesRef.current;
                                handelServicesScroll(section);
                            }}>Services</div>
                        </li>
                        <li className={`border border-x-0 border-t-0 pb-1 border-transparent border-4 ${activeSection === "showcase" ? 'text-accent border-accent' : 'border-contrast'} hover:text-accent hover:border-accent cursor-pointer`}>
                            <div onClick={() => {
                                const section = props.refMap.showcaseRef.current;
                                handelShowcaseScroll(section);
                            }}>Showcase</div>
                        </li>
                        <li className={`border border-x-0 border-t-0 pb-1 border-transparent border-4 ${activeSection === "workProcess" ? 'text-accent border-accent' : 'border-contrast'} hover:text-accent hover:border-accent cursor-pointer`}>
                            <div onClick={() => {
                                const section = props.refMap.workProcessRef.current;
                                handelWorkProcessScroll(section);
                            }}>Work Process</div>
                        </li>
                        <li className={`border border-x-0 border-t-0 pb-1 border-transparent border-4 ${activeSection === "aboutUs" ? 'text-accent border-accent' : 'border-contrast'} hover:text-accent hover:border-accent cursor-pointer`}>
                            <div onClick={() => {
                                const section = props.refMap.aboutUsRef.current;
                                handelAboutUsScroll(section);
                            }}>About Us</div>
                        </li>
                        <li className={`border border-x-0 border-t-0 pb-1 border-transparent border-4 ${activeSection === "contactUs" ? 'text-accent border-accent' : 'border-contrast'} hover:text-accent hover:border-accent cursor-pointer`}>
                            <div onClick={() => {
                                const section = props.refMap.contactUsRef.current;
                                handelContactUsScroll(section);
                            }}>Contact Us</div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}