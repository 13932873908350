import callifarImage from '../assets/videos/showcase/callifar.png';
import footflupsImage from '../assets/videos/showcase/footflups.png';
import accoeasyImage from '../assets/videos/showcase/accoeasy.png';
import hotelImage from '../assets/videos/showcase/hotel.png';

export const ShowcaseComponent = (props) => {
    return (
        <div ref={props.showcaseRef} data-section id="showcase" className="w-full pt-20 md:pt-10">
            <div className="w-full text-center text-3xl">
                <div className="text-2xl text-contrast font-medium">
                    <div className='px-8 md:px-96 pb-5'>
                        <hr className='border border-accent' />
                    </div>
                    Our
                    <span className="text-5xl text-accent font-semibold">
                        &nbsp;Work
                    </span>
                </div>
                <div className='flex flex-wrap items-center justify-center gap-10 w-full py-10 md:px-0 px-8'>
                    <div className="flex flex-wrap bg-[#fff] items-center justify-center w-full h-128 gap-2 md:w-4/12 border border-2 border-accent2 border-groove rounded-xl">
                        <span className='text-accent2 pt-1 text-xl font-medium'>Footiefluffs</span>
                        <img src={footflupsImage} alt="footflups" className='w-full h-full rounded-xl ' />
                        <div className='flex w-full gap-1 mb-4 px-2 md:px-0 justify-center'>
                            <span className='flex items-center text-xs bg-accent2 bg-opacity-[0.2] font-medium text-[#000000] px-2 py-1 rounded'>
                                Web App
                            </span>
                            <span className='flex items-center text-xs bg-accent2 bg-opacity-[0.2] font-medium text-[#000000] px-2 py-1 rounded'>
                                UI/UX Design
                            </span>
                            <span className='flex items-center text-xs bg-accent2 bg-opacity-[0.2] font-medium text-[#000000] px-2 py-1 rounded'>
                                UI/UX Research
                            </span>
                            <span className='flex items-center text-xs bg-accent2 bg-opacity-[0.2] font-medium text-[#000000] px-2 py-1 rounded'>
                                Responsive
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-wrap bg-[#fff] items-center justify-center w-full h-128 gap-2 md:w-4/12 border border-2 border-accent border-groove rounded-xl">
                        <span className='text-accent pt-1 text-xl font-medium'>Callifar</span>
                        <img src={callifarImage} alt="callifar" className='w-full h-full rounded-xl ' />
                        <div className='flex w-full gap-1 mb-4 px-2 md:px-0 justify-center'>
                            <span className='flex items-center text-xs bg-accent bg-opacity-[0.2] font-medium text-[#000000] px-2 py-1 rounded'>
                                Mobile App
                            </span>
                            <span className='flex items-center text-xs bg-accent bg-opacity-[0.2] font-medium text-[#000000] px-2 py-1 rounded'>
                                UI/UX Design
                            </span>
                            <span className='flex items-center text-xs bg-accent bg-opacity-[0.2] font-medium text-[#000000] px-2 py-1 rounded'>
                                UI/UX Research
                            </span>
                            <span className='flex items-center text-xs bg-accent bg-opacity-[0.2] font-medium text-[#000000] px-2 py-1 rounded'>
                                Video Calling App
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-wrap bg-[#fff] items-center justify-center w-full h-128 gap-2 md:w-4/12 border border-2 border-accent border-groove rounded-xl">
                        <span className='text-accent pt-1 text-xl font-medium'>Accoeasy</span>
                        <img src={accoeasyImage} alt="accoeasy" className='w-full h-full rounded-xl ' />
                        <div className='flex w-full gap-1 mb-4 px-2 md:px-0 justify-center'>
                            <span className='flex items-center text-xs bg-accent bg-opacity-[0.2] font-medium text-[#000000] px-2 py-1 rounded'>
                                Desktop Application
                            </span>
                            <span className='flex items-center text-xs bg-accent bg-opacity-[0.2] font-medium text-[#000000] px-2 py-1 rounded'>
                                Accounting App
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-wrap bg-[#fff] items-center justify-center w-full h-128 gap-2 md:w-4/12 border border-2 border-accent2 border-groove rounded-xl">
                        <span className='text-accent2 pt-1 text-xl font-medium'>Hotel Management</span>
                        <img src={hotelImage} alt="hotel" className='w-full h-full rounded-xl ' />
                        <div className='flex w-full gap-1 mb-4 px-2 md:px-0 justify-center'>
                            <span className='flex items-center text-xs bg-accent2 bg-opacity-[0.2] font-medium text-[#000000] px-2 py-1 rounded'>
                                Web App
                            </span>
                            <span className='flex items-center text-xs bg-accent2 bg-opacity-[0.2] font-medium text-[#000000] px-2 py-1 rounded'>
                                Responsive
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-wrap items-center justify-center w-full h-128 gap-2 md:w-4/12">
                        <div className='w-full text-center text-xl font-medium text-accent'>
                            And Many More...
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}