import React, { useRef } from 'react';
import './App.css';
import { ServicesComponent } from './app/service.component';
import { ShowcaseComponent } from './app/showcase.component';
import { WorkProcessComponent } from './app/work-process.component';
import { AboutUsComponent } from './app/aboutus.component';
import { ContactUsComponent } from './app/contactus.component';
import { HomeComponent } from './app/home.component';
import { NavbarComponent } from './app/navbar.component';
import { ContactFormComponent } from './app/contactform.component';
import { Analytics } from '@vercel/analytics/react';


function App() {

  const homeRef = useRef(null);
  const servicesRef = useRef(null);
  const showcaseRef = useRef(null);
  const workProcessRef = useRef(null);
  const aboutUsRef = useRef(null);
  const contactUsRef = useRef(null);

  return (
    <div className='w-full'>
      <Analytics />
      <NavbarComponent
        refMap={{ homeRef, servicesRef, showcaseRef, workProcessRef, aboutUsRef, contactUsRef }}
      />
      <HomeComponent homeRef={homeRef} />
      <ServicesComponent servicesRef={servicesRef} />
      <ShowcaseComponent showcaseRef={showcaseRef} />
      <WorkProcessComponent workProcessRef={workProcessRef} />
      <AboutUsComponent aboutUsRef={aboutUsRef} />
      <ContactUsComponent contactUsRef={contactUsRef} />
      <ContactFormComponent />
    </div>
  );
}


export default App;
