import phoneImage from '../assets/images/contactus/phone.svg';
import emailImage from '../assets/images/contactus/email.svg';
import whatsappImage from '../assets/images/contactus/whatsapp.svg';
import instagramImage from '../assets/images/contactus/instagram.svg';
import linkedinImage from '../assets/images/contactus/linkedin.svg';

export const ContactUsComponent = (props) => {
    return (
        <div ref={props.contactUsRef} data-section id="contactUs" className="w-full bg-contrast pt-5 px-10 md:px-12 pb-5 bg-cyan-100">
            <div className="w-full text-center text-3xl text-accent font-medium">
                Get in Touch with Us
            </div>
            <p className="text-md text-text-primary text-justify pt-5 pb-4">
                Have questions about our services? Ready to discuss a potential project? Curious about how we can assist you? Reach out to us, and let's start a conversation. Your digital journey begins with a simple step getting in touch with us. Feel free to contact us using the details provided below or by using the form to send us a message. We're all ears and excited to hear from you!
            </p>
            <p className="text-md text-text-primary text-justify pb-5">
                We're here to ensure that your inquiries are answered promptly and your journey toward innovative solutions begins seamlessly. Get in touch today and let's turn your ideas into reality!
            </p>
            <span className="text-xl font-medium text-accent">Contact Information</span>
            <p className='flex w-full items-center px-2 py-1 text-accent font-bold pt-3'><img src={phoneImage} alt="phone" className="w-5 h-5 mr-4" /><a href='tel:+918120423020'>+91 8120423020</a></p>
            <p className='flex items-center w-full px-2 py-1 text-justify text-accent font-bold'><img src={emailImage} alt="email" className="w-5 h-5 mr-4" /><a href='mailto:infix.official@outlook.com'>infix.official@outlook.com</a></p>
            <div className='flex w-full items-center justify-center pt-5 md:pt-0'>
                <a href="https://www.linkedin.com/company/infix-official/" target="_blank"><img src={linkedinImage} alt="phone" className="w-8 h-8 mr-4" /></a>
                <a href="https://wa.me/918120423020" target="_blank"><img src={whatsappImage} alt="phone" className="w-8 h-8 mr-4" /></a>
                <a href="https://www.instagram.com/infix_official_/" target="_blank"><img src={instagramImage} alt="phone" className="w-8 h-8 mr-4" /></a>
            </div>
        </div>
    )
}