import image1 from '../assets/images/home/image1.png';


export const HomeComponent = (props) => {

  return (
    <div ref={props.homeRef} data-section id="home" className='flex flex-wrap w-full md:p-20 pb-0 pt-10'>
      <div className='flex justify-center items-center w-full md:w-6/12 h-inherit '>
        <p className='font-mono text-md   pb-5 pt-16 md:pb-0 md:text-3xl text-contrast font-thin'>
          <span className='font-thin text-accent2 text-lg md:text-3xl'>&lt;&gt;</span><br />
          &nbsp;&nbsp;<span className='text-accent font-semibold text-md md:text-3xl'>Your Vision,</span> <span className='text-accent font-semibold text-md md:text-3xl'>Our Dedication</span><br />
          &nbsp;&nbsp;<span className=' text-md md:text-2xl'>Let's Craft the Extraordinary.</span><br />
          <span className='font-thin text-accent2 text-lg md:text-3xl'>&lt;/&gt;</span>
        </p>
      </div>
      <div className='flex justify-center w-full md:w-6/12 h-inherit px-5 pt-5'>
        <img src={image1} alt="img1" className='w-fit h-inherit' />
      </div>
    </div>
  )
}