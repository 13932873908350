export const AboutUsComponent = (props) => {
    return (
        <div ref={props.aboutUsRef} id="aboutUs" data-section className="w-full pt-20 md:pt-10">
            <div className="w-full text-center text-3xl">
                <div className="text-xl text-contrast font-medium">
                    <div className='px-8 md:px-96 pb-5'>
                        <hr className='border border-accent' />
                    </div>
                    Know
                    <span className="invisible">AboutAboutAboutUs</span>
                </div>
                <div className="text-5xl text-accent font-semibold">
                    About Us
                </div>
            </div>
            <div className='flex flex-wrap items-center justify-justify gap-10 w-full py-10 md:px-0 px-8 text-contrast'>
                <div className="text-justify w-full px-2 md:px-16 text-lg">
                    Welcome to <span className="text-xl text-accent font-medium">INFIX</span>  where creativity knows no bounds. We are a dynamic duo dedicated to empowering your vision with cutting-edge solutions. Our shared purpose is to provide tailored solutions that drive your success in the digital landscape. With a strong passion for innovation and an unwavering dedication to staying at the forefront of technological advancements, we are here to transform your ideas into reality.
                </div>
                <div className="text-justify w-full px-2 md:px-16 text-lg">
                    <p className="text-accent font-medium text-2xl pb-2">Why Choose Us ?</p>
                    <p>Empower your digital ambitions with our premier <span className="text-xl text-accent2 font-medium">IT Freelancing Services</span>. We bring expertise and uniquely designed solutions, ensuring that your specific project needs are met with precision. At INFIX, we believe in blending quality, effective communication, and timeliness to offer a holistic experience. Embrace our future-focused solutions that propel your business forward in the dynamic digital age. </p>
                </div>
                <div className="text-justify w-full px-2 md:px-16 text-lg">
                    <p className="text-accent font-medium text-2xl pb-2">Our Mission</p>
                    Our mission at INFIX is to empower businesses with tailored solutions that not only redefine possibilities but also drive unparalleled digital success. We are committed to being the catalyst that transforms your goals into reality, leveraging our expertise and passion for innovation.
                </div>
                <div className="text-justify w-full px-2 md:px-16 text-lg">
                    <p className="text-accent font-medium text-2xl pb-2">Our Vision</p>
                    At INFIX, we envision a future where seamless technology integration becomes the cornerstone of business transformation, propelling growth, and accelerating global innovation. Our vision is to play a pivotal role in this transformation, helping businesses thrive in the ever-evolving digital landscape.
                </div>
            </div>
        </div>
    )
}