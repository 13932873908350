import { useRef, useState } from "react";
import contactImage from '../assets/images/contactus/contact.svg';

export const ContactFormComponent = () => {
    const [isFormOpen, setIsFormOpen] = useState(false);
    const toggleForm = () => {
        setIsFormOpen(!isFormOpen);
    };

    return (
        <div className="fixed bottom-4 right-4 z-50">
            <div
                className="bg-accent text-white py-6 px-6 cursor-pointer rounded-full shadow-md"
                onClick={toggleForm}
            >
                <img src={contactImage} alt="Contact" className="w-8 h-8" />
            </div>
            {isFormOpen && <ContactForm closeForm={() => setIsFormOpen(false)} />}
        </div>
    )
}

const ContactForm = ({ closeForm }) => {



    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [mobileNumberError, setMobileNumberError] = useState("");
    const [messageError, setMessageError] = useState("");

    const formRef = useRef(null);

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const numberPattern = /^\d+$/

    const sendEmail = async (data) => {
        try {
            const response = await fetch('https://infix-backend.onrender.com/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            if (!response.ok) {
                console.log("Facing issue");
            }
        } catch (error) {
            console.error('Error sending email:', error);
        }
    };

    const formHandler = (ev) => {

        const firstName = ev.target[0].value;
        const lastName = ev.target[1].value;
        const email = ev.target[2].value;
        const mobileNumber = ev.target[3].value;
        const message = ev.target[4].value;
        let isError = false;
        if (firstName === "" || firstName.length === 0) {
            setFirstNameError("First name required");
            isError = true;
        }
        if (lastName === "" || lastName.length === 0) {
            setLastNameError("Last name required");
            isError = true;
        }
        if (email === "" || email.length === 0) {
            setEmailError("Email address required")
            isError = true;
        } else if (!emailPattern.test(email)) {
            setEmailError("Invalid email address");
            isError = true;
        }
        if (mobileNumber === "" || mobileNumber.length === 0) {
            setMobileNumberError("Mobile number required");
            isError = true;
        } else if (mobileNumber.length < 10 || !numberPattern.test(mobileNumber)) {
            setMobileNumberError("Invalid Mobile number");
            isError = true;
        }
        if (message === "" || message.length === 0) {
            setMessageError("Message required");
            isError = true;
        }

        if (!isError) {
            const data = {
                firstName,
                lastName,
                email,
                mobileNumber,
                message
            };
            sendEmail(data);
            formRef.current.reset();
            setFirstNameError("");
            setLastNameError("");
            setEmailError("");
            setMobileNumberError("");
            setMessageError("");
        }


        ev.preventDefault();
    }


    return (
        <div className="fixed bottom-12 right-4 z-50 bg-text-primary p-4 rounded-lg shadow-2xl w-80">
            <div className="flex w-full">
                <h2 className="text-3xl text-accent w-4/6 font-semibold mb-4">Contact Us</h2>
                <div className="text-3xl font-thin  w-2/6 text-right text-cyan-700 px-2">
                    <span className="cursor-pointer" onClick={closeForm}>x</span>
                </div>
            </div>
            <form ref={formRef} onSubmit={formHandler}>
                <div className="mb-4 flex pt-4">
                    <div className="mr-2 w-1/2">
                        <label className="block text-input-label font-medium mb-1">First Name</label>
                        <input
                            type="text"
                            className="w-full border border-input-border rounded-sm py-2 px-3 focus:outline-none"
                            placeholder="First Name"
                        />
                        <label className="block text-danger text-xs mb-1">{firstNameError}</label>
                    </div>
                    <div className="ml-2 w-1/2">
                        <label className="block text-input-label font-medium mb-1">Last Name</label>
                        <input
                            type="text"
                            className="w-full border  border-input-border rounded-sm py-2 px-3 focus:outline-none"
                            placeholder="Last Name"
                        />
                        <label className="block text-danger text-xs mb-1">{lastNameError}</label>
                    </div>
                </div>
                <div className="mb-4">
                    <label className="block text-input-label font-medium mb-1">Email</label>
                    <input
                        type="text"
                        className="w-full border  border-input-border rounded-sm py-2 px-3 focus:outline-none"
                        placeholder="Email Address"
                    />
                    <label className="block text-danger text-xs mb-1">{emailError}</label>
                </div>
                <div className="mb-4">
                    <label className="block text-input-label font-medium mb-1">Mobile Number</label>
                    <input
                        type="text"
                        className="w-full border  border-input-border rounded-sm py-2 px-3 focus:outline-none"
                        placeholder="Mobile Number"
                    />
                    <label className="block text-danger text-xs mb-1">{mobileNumberError}</label>
                </div>
                <div className="mb-4">
                    <label className="block text-input-label font-medium mb-1">Message</label>
                    <textarea
                        className="w-full border  border-input-border rounded-sm py-2 px-3 h-20 focus:outline-none"
                        placeholder="Enter your message"
                    />
                    <label className="block text-danger text-xs mb-1">{messageError}</label>
                </div>
                <div className="w-full text-center">
                    <button
                        type="submit"
                        className="bg-accent text-text-primary hover:font-medium py-2 mb-2 px-4 rounded-sm"
                    >
                        Send Message
                    </button>
                </div>
            </form>
        </div>
    );
};